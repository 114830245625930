<template>
  <v-btn
    v-if="createMove"
    icon
    color="green"
    class="mr-2"
    :disabled="movable"
    @click="check"
  >
    <v-icon>
      fa fa-check
    </v-icon>
  </v-btn>
</template>

<script lang="babel" type="text/babel">
import treeNodeAction from '@/components/tree/nodeAction/treeNodeAction'
export default {
  mixins: [treeNodeAction],
  methods: {
    async check() {
      if(this.move.action === 'inside') {
        await this.callMoveApi(this.moveNode, this.item.id, 'inside')
      }

      else {
        await this.callMoveApi(this.moveNode, this.item.id, this.move.action)
      }

      this.clearMove()
      this.treeActions.refreshTree()
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>